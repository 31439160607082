<template>
  <div class="srticle">
    <div class="srticle-con">
      <p class="title">{{detailInfo.title}}</p>
      <div class="describe">
        <p>
          <span>{{detailInfo.createTime}}</span>
        </p>
      </div>
      <div class="content">
        {{detailInfo.content}}
      </div>
      <div class="img-box">
        <img v-for="(item,index) in detailInfo.imgs" :key="index" :src="item" alt="">
      </div>
    </div>
    <comment
        @doSend="doSend($event)"
        @doChidSend="doChidSend(arguments)"
        :commentList="commentList"
        :commentNum="commentNum"
        :label="label"
        :avatar="avatar"
        :placeholder="placeholder"
        :minRows="minRows"
        :maxRows="maxRows"
      ></comment>
  </div>
</template>

<script>
import Comment from "../../components/common/Comment.vue"; // 开发文件
export default {
  name: 'BbjtwebsiteSrticleDetail',
  components: {
    Comment,
  },
  data() {
    return {
      userInfo: {},
      label: "SVIP",
      placeholder: "说点什么吧",
      minRows: 4,
      maxRows: 4,
      commentNum: 2,
      avatar: require("../../assets/topNav/9466c5e7-ad13-4563-83fc-389ce3ac21f6.png"),
      commentList: [],
      detailInfo: {}
    };
  },

  mounted() {
    let userMap = JSON.parse(localStorage.getItem("userMap"))
    this.userInfo = userMap
    this.avatar = this.userInfo?this.userInfo.headImg:this.avatar
    console.log(this.userInfo);
    console.log(this.$route.query.id);
    this.getIdDetail()
    this.getPostsByTar()
  },

  methods: {
    async getIdDetail() {
      let data = {
        id:this.$route.query.id
      }
      let res = await this.api.post(this.apiPath.getPostById,data);
      console.log(res);
      this.detailInfo = res.data
    },
    async getPostsByTar() {
      // 查询所有评论
      let data = {
        target_id:this.$route.query.id
      }
      let res = await this.api.post(this.apiPath.getPostsByTarId,data);
      console.log(res);
      this.commentList = res.data
      console.log(this.commentList);
      this.commentNum = this.commentList.length
    },
    async doSend(content) {
      let data = {
        user_id: this.userInfo.id,
        target_id:this.$route.query.id,
        content: content
      }
      let res = await this.api.post(this.apiPath.addComment,data);
      // console.log("初始发送按钮点击事件：" + content);
      this.$message({
        type: 'success',
        message: res.msg
      });
      this.getPostsByTar()
    },
    async doChidSend(args) {
      // console.log("评论区发送按钮点击事件：");
      // console.log("content=" + args[0]);
      // console.log("targetUserId=" + args[1]);
      // console.log("父级评论id=" + args[2]);
      let data = {
        from_id: this.userInfo.id,
        to_id:args[1],
        content: args[0],
        conversation_id: args[2]
      }
      let res = await this.api.post(this.apiPath.addChat,data);
      this.$message({
        type: 'success',
        message: res.msg
      });
      this.getPostsByTar()
    },
  },
};
</script>

<style lang="less" scoped>
.srticle {
  // width: 1150px;
  margin: auto;
  .srticle-con {
    .title {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
    }
    .describe {
      height: 50px;
      background: #F8F8F8;
      text-align: center;
      p{
       line-height: 50px;
       font-size: 13px;
      }
    }
    .content {
      font-size: 16px;
      padding: 20px 150px;
    }
    .img-box {
      display: grid;
      img {
        width: 500px;
        margin: auto;
      }
    }
  }
}
@media screen and (max-width: 750px) {
 .srticle .srticle-con .content {
  padding: 20px 15px;
 }
 
}
</style>